<template>
  <div v-for="cta in ctas" :key="cta.id" class="cta" :class="[cta.type]">
    <div
      class="flex"
      :class="[cta.dismissible ? 'justify-between' : 'justify-start']"
    >
      <div :class="['icon-box', cta.type]">
        <i :class="getIconClass(cta.type)"></i>
      </div>

      <div v-if="cta.dismissible" class="button-container">
        <button @click="dismiss(cta)" class="closebutton">
          <i class="mdi mdi-close"></i>
        </button>
      </div>
    </div>

    <div>
      <p>
        <strong class="font-semibold">{{ $t(cta.content.title) }}</strong>
      </p>
      <p class="text-pretty">
        {{ $t(cta.content.body) }}
      </p>
    </div>
    <div class="space-x-2">
      <button
        v-if="cta.content.cta1"
        @click="executeAction(cta.content.cta1)"
        class="button primarybutton"
        :class="[cta.type]"
      >
        {{ $t(cta.content.cta1.text) }}
      </button>
      <button
        v-if="cta.content.cta2"
        @click="executeAction(cta.content.cta2)"
        class="button secondarybutton"
      >
        {{ $t(cta.content.cta2.text) }}
        <i class="mdi mdi-arrow-right"></i>
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'

const modalConfig = useModals()
const router = useRouter()
const { userProfile } = useKeycloak()
const runtimeConfig = useRuntimeConfig()
const ctas = ref([])
const props = defineProps({
  location: {
    type: String,
    default: '',
    validator: value =>
      [
        'collection-settings',
        'organisation-settings',
        'recognition-slideover',
        'export-page',
        'user-menu',
        'share-credits',
      ].includes(value),
  },
})

onMounted(async () => {
  parseCookie()
  await loadCTAs()
})

const dismissedCTAs = ref([])
const ctaCookie = useCookie('transkribus-ctas', {
  maxAge: 60 * 60 * 24 * 365,
})
const parseCookie = () => {
  try {
    dismissedCTAs.value = ctaCookie.value ? ctaCookie.value : []
    dismissedCTAs.value.forEach(cta => {
      cta.date = new Date(cta.date).toISOString().split('T')[0]
    })
  } catch (error) {
    console.error('Error parsing cookie:', error)
    dismissedCTAs.value = []
  }
}

const dismiss = cta => {
  dismissedCTAs.value.push({
    id: cta.id,
    date: new Date().toISOString().split('T')[0],
  })
  ctaCookie.value = JSON.stringify(dismissedCTAs.value)
  ctas.value = ctas.value.filter(b => b.id !== cta.id)
}

const isCTADismissed = cta => {
  const dismissedCTA = dismissedCTAs.value.find(
    dismissedCTA => dismissedCTA.id === cta.id
  )
  if (dismissedCTA) {
    if (!cta.startdate) {
      return true
    }
    const ctaStartDate = new Date(cta.startdate).toISOString().split('T')[0]
    return ctaStartDate <= dismissedCTA.date
  }
  return false
}

const isActive = cta => {
  let env = runtimeConfig.public.ENVIRONMENT_NAME
  if (!cta.conditions.instances?.includes(env)) {
    return false
  }

  if (cta.location !== props.location) {
    return false
  }

  const currentDate = new Date().toISOString()
  if (cta.startdate) {
    const ctaStartDate = new Date(cta.startdate).toISOString()
    if (ctaStartDate > currentDate) {
      return false
    }
  }
  if (cta.enddate) {
    const ctaEndDate = new Date(cta.enddate).toISOString()
    if (ctaEndDate < currentDate) {
      return false
    }
  }

  const userRole = getUserRole()
  const allowedRoles = cta.conditions.role
  if (!allowedRoles.includes(userRole) && !allowedRoles.includes('All')) {
    return false
  }

  return true
}

const getUserRole = () => {
  if (userProfile.Team) {
    return 'Team'
  } else if (userProfile.Individual) {
    return 'Individual'
  } else if (userProfile.Scholar) {
    return 'Scholar'
  }else if (userProfile.Era) {
    return 'Era'
  } else if (userProfile.Epoch) {
    return 'Epoch'
  } else if (userProfile.Eon) {
    return 'Eon'
  }
  return ''
}

const loadCTAs = async () => {
  try {
    const files = import.meta.glob('~/assets/ctas/*.json')
    const ctaPromises = Object.keys(files).map(async path => {
      const cta = await files[path]()
      return cta.default
    })
    ctas.value = await Promise.all(ctaPromises)
    ctas.value = ctas.value.filter(
      cta =>
        cta.location === props.location && isActive(cta) && !isCTADismissed(cta)
    )

    // Only show one CTA for now, this can be changed later if we want to show multiple CTAs
    if (ctas.value.length > 1) {
      ctas.value = [ctas.value[0]]
    }
  } catch (error) {
    console.error('Error loading ctas:', error)
  }
}

const getIconClass = type => {
  switch (type) {
    case 'info':
      return 'mdi mdi-bullhorn'
    case 'warning':
      return 'mdi mdi-alert'
    case 'promo':
      return 'mdi mdi-play-box-multiple'
    case 'upgrade':
      return 'mdi mdi-arrow-up-circle'
    default:
      return ''
  }
}

function executeAction(cta) {
  if (cta.url) {
    navigateToUrl(cta.url)
  } else if (cta.modal) {
    openModal(cta.modal)
  }
}

const navigateToUrl = url => {
  if (url.startsWith('/')) {
    router.push(url)
  } else {
    window.location.href = url
  }
}

async function openModal(trigger) {
  const modals = await modalConfig.getModals()
  const modal = modals.find(modal => modal.trigger === trigger)
  modal.open()
}

onMounted(async () => {
  parseCookie()
  await loadCTAs()
})
</script>

<style scoped>
.cta {
  --info-text-color: #fff;
  --info-bg-color: #bg-themewolpi-50;
  display: flex flex-col;
  align-items: center;
  @apply space-y-2 p-4 min-h-[44px] rounded-[8px];
}

.cta.info {
  @apply text-white;
  background: linear-gradient(90deg, #193060 0.01%, #2a59bb 99.99%);
}

.cta.upgrade {
  @apply text-white;
  text-shadow: 0px 1px 10px rgba(0, 0, 0, 0.25);
  background: linear-gradient(90deg, #8f855f 0%, #c9bd92 100%);
}

.cta.warning {
  @apply text-white bg-danger-200;
}

.cta.promo {
  @apply text-themewolpi-50 bg-promo;
}

.button-container {
  display: flex;
  justify-content: flex-end;
}

.icon-box {
  @apply flex justify-center items-center min-w-[28px] h-[28px] rounded-[6px] justify-self-end;
}

.icon-box.info {
  @apply bg-white text-themewolpi-50;
}

.icon-box.warning {
  @apply bg-white text-danger-200;
}

.icon-box.promo {
  @apply text-promo bg-themewolpi-50;
}

.icon-box.upgrade {
  @apply bg-white text-[#A39971];
}

.closebutton {
  @apply bg-none border-none cursor-pointer w-6 h-6 flex items-center justify-center;
}

.closebutton.info {
  @apply text-white;
}

.closebutton.warning {
  @apply text-white;
}

.closebutton.promo {
  @apply text-themewolpi-50;
}

.closebutton.upgrade {
  @apply text-white;
}

.primarybutton {
  @apply border-none cursor-pointer rounded-[8px] p-2;
}

.primarybutton.info {
  @apply bg-white text-themewolpi-50;
}

.primarybutton.warning {
  @apply bg-white text-danger-200;
}

.primarybutton.promo {
  color: #f1e8d7;
  background-color: #193060;
}

.primarybutton.upgrade {
  @apply bg-white text-[#A39971];
}

.secondarybutton {
  @apply border-none cursor-pointer rounded-[8px] ;
}

.secondarybutton.info {
  @apply text-white;
}

.secondarybutton.warning {
  @apply text-white;
}

.secondarybutton.promo {
  @apply text-themewolpi-50;
}

.secondarybutton.upgrade {
  @apply text-white;
}
</style>
